<template>
  <div class="mr-1 badge " :class="(orderQuantity - shippedQuantity) < totalBoxedItemCount ? 'badge-warning' : 'badge-light-primary'">
    <small class="text-muted">Boxed</small>
    <h4>{{ totalBoxedItemCount || '-' }}</h4>
  </div>
</template>

<script>
import useFbmState from '../useFbmStates'

export default {
  props: {
    pshProductId: {
      type: Number,
      default: 0,
    },
    orderQuantity: {
      type: Number,
      default: 0,
    },
    shippedQuantity: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const {
      boxes,
    } = useFbmState()
    return {
      boxes,
    }
  },
  computed: {
    totalBoxedItemCount() {
      let totalBoxedCount = 0
      // eslint-disable-next-line no-restricted-syntax
      for (const box of this.boxes) { // Use .value to access reactive data
        const matchingItems = box.shipBox.filter(item => item.pshProductId === this.pshProductId)
        totalBoxedCount += matchingItems.reduce((sum, item) => sum + item.updatedQuantity, 0)
      }
      return totalBoxedCount
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
