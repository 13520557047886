<template>
  <div class="d-flex">
    <div>
      <label for="">Quantity</label>
      <dx-util-number-box v-model="currentCount" class="mr-1" :value="1" :show-spin-buttons="true" :min="1" width="100" />
    </div>
    <div v-for="box in boxes" :key="box.no" style="padding-top: 21px;" class="d-flex mx-half">
      <dx-util-button :text="'Box ' + box.no" type="success" class="mx-1" @click="updateBoxes(box.no)" />
    </div>
  </div>
</template>

<script>
import notify from '@/@robustshell/utils/notify'
import { Notify } from '@/@robustshell/utils'
import useFbmState from '../useFbmStates'

export default {
  props: {
    item: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      currentCount: 1,
    }
  },
  setup() {
    const {
      boxes,
      currentOrder, getShippingLabels, orderItems, selectedOrderItems,
    } = useFbmState()
    return {
      boxes,
      currentOrder,
      getShippingLabels,
      orderItems,
      selectedOrderItems,
    }
  },
  methods: {
    updateBoxes(boxNo) {
      if (this.item.orderQuantity < this.currentCount) {
        Notify.error('quantity should be less or equal to order quantity')
        return
      }
      const matchingItemInShipBox = this.boxes[boxNo - 1]?.shipBox.find(item => item.pshProductId === this.item.pshProductId)

      if (matchingItemInShipBox) {
        Notify.error('Item already added to this box')
        return
      }

      const item = { ...this.item, updatedQuantity: this.currentCount }
      const matchingBoxIndex = this.boxes.findIndex(box => box.no === boxNo)
      if (matchingBoxIndex !== -1) {
        const updatedShipBox = [...this.boxes[matchingBoxIndex].shipBox, item]
        this.$set(this.boxes[matchingBoxIndex], 'shipBox', updatedShipBox)
      } else {
        notify.error(`Box with number ${boxNo} not found`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
