<template>
  <dx-util-popup
    ref="fbmBoxingPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="false"
    :show-close-button="true"
    :show-title="true"
    :title="popupTitle"
    :full-screen="true"
    :position="'center'"
    @shown="fbmBoxingPopupShown"
    @hidden="fbmBoxingPopupHidden"
  >
    <dx-util-scroll-view height="100%">
      <div class="container-fluid">
        <div class="row">
          <div class="col-6 border-right">
            <dx-util-validation-group>
              <div class="d-flex justify-content-start">
                <dx-util-number-box
                  v-show="false"
                  v-model="box.count" :disabled="true"
                  type="default"
                  label=" " :width="50"
                  :min="1" class="mr-half"
                >
                  <dx-util-validator>
                    <dx-util-required-rule message="Count is required a field" />
                  </dx-util-validator>
                </dx-util-number-box>
                <dx-util-number-box v-model="box.length" type="default"
                  label="Length (in)" format="#0.01 in"
                  class="mr-1" :min="0.01"
                >
                  <dx-util-validator>
                    <dx-util-range-rule :min="0.1" message="Length is required field" />
                  </dx-util-validator>
                </dx-util-number-box>
                <dx-util-number-box v-model="box.height" type="default"
                  label="Height (in)" format="#0.01 in"
                  class="mr-1" :min="0.01"
                >
                  <dx-util-validator>
                    <dx-util-range-rule :min="0.1" message="Height is required field" />
                  </dx-util-validator>
                </dx-util-number-box>
                <dx-util-number-box v-model="box.width" type="default"
                  label="Width (in)" format="#0.01 in"
                  class="mr-1" :min="0.01"
                >
                  <dx-util-validator>
                    <dx-util-range-rule :min="0.1" message="Width is required field" />
                  </dx-util-validator>
                </dx-util-number-box>
                <dx-util-number-box v-model="box.weightLb" type="default"
                  label="Weight (lb)" format="#0 lb"
                  :min="0" class="mr-1"
                />
                <dx-util-number-box
                  v-model="box.weightOz" type="default"
                  label="Weight (oz)" format="#0 oz"
                  class="mr-1" :min="0"
                />
                <dx-util-number-box
                  v-model="box.count" type="default"
                  label="Box Count"
                  class="mr-1" :min="1"
                  :show-spin-buttons="true"
                />
              <div style="padding-top: 4px;" class="d-flex align-items-center">
                <dx-util-button text="Create Boxes" type="default" @click="createBoxes" />
              </div>
            </div>
            </dx-util-validation-group>
          <div class="pt-3">
            <div class="text-warning pl-2">
              Please first select the box, then enter the quantity of the item and click plus button.
            </div>
            <div v-for="item in orderItems" :key="item.id" class="custom-border my-1">
              <div class="d-flex">
                <div class="align-self-center" role="button">
                  <img :src="item.smallImage || require('@/assets/images/undraw/no_images.svg')" style="height: 80px !important; width: 80px !important; margin-right: 0.625rem" class="rounded text-white">
                </div>
                <div class="flex-grow-1 mb-half">
                  <small class="text-muted">Product Name</small>
                  <h5>
                    {{ item.productName }}
                  </h5>
                  <small class="text-muted">SKU</small>
                  <span>
                    {{ item.orderSku }}<copy-button :copy-value="item.orderSku" />
                  </span>
                  <div v-if="currentOrder.platform === 'AmazonSP'" class="d-flex">
                    <fbm-item-weight :item="item" />
                  </div>
                </div>
                <div class="text-center pt-1">
                  <div class="d-flex">
                    <div class="mr-1">
                      <small class="text-muted">
                        Quantity
                      </small>
                      <h4>{{ item.orderQuantity || '-' }}</h4>
                    </div>
                    <div class="mr-1">
                      <small class="text-muted">Shipped</small>
                      <h4>{{ item.shippedQuantity || '-' }}</h4>
                    </div>
                    <fbm-boxed-count :psh-product-id="item.pshProductId" :order-quantity="item.orderQuantity" :shipped-quantity="item.shippedQuantity" />
                  </div>
                </div>
              </div>
              <div v-if="showBoxes" class="d-flex justify-content-start border-top mt-half bg-light-primary rounded p-half">
                <fbm-boxing-counter :item="item" />
              </div>
            </div>
          </div>

          </div>
          <div v-if="showBoxes" class="col-6">
            <dx-accordion
              id="accordion-container"
              :data-source="boxes"
              :collapsible="true"
              :multiple="true"
              :animation-duration="500"
            >
              <template #title="{ data }">
                <div>
                  <dx-util-button icon="remove" type="danger" styling-mode="text" @click="removeBox(data.no)" />
                  <span>
                    Box {{ data.no }}
                    <span v-if="data.insuredValue && data.insuredValue > 0" class="text-muted">
                      (Insurance value: ${{ data.insuredValue }})
                    </span>
                  </span>
                </div>
              </template>
              <template #item="{ data }">
                <div>
                  <div class="d-flex justify-content-between">
                    <dx-util-number-box v-model="data.length" type="default"
                      label="Length (in)" format="#0.00 in"
                      class="mr-1" :min="0.01"
                    />
                    <dx-util-number-box v-model="data.height" type="default"
                      label="Height (in)" format="#0.00 in"
                      class="mr-1" :min="0.01"
                    />
                    <dx-util-number-box v-model="data.width" type="default"
                      label="Width (in)" format="#0.00 in"
                      class="mr-1" :min="0.01"
                    />
                    <dx-util-number-box v-model="data.weightLb" type="default"
                      label="Weight (lb)" format="#0 lb"
                      :min="0" class="mr-1"
                    />
                    <dx-util-number-box
                      v-model="data.weightOz" type="default"
                      label="Weight (oz)" format="#0 oz"
                      class="mr-1" :min="0"
                    />
                    <dx-util-number-box
                      v-if="currentOrder.fbmResourceType === 'AMAZON'"
                      v-model="data.insuredValue" type="default"
                      label="Insurance" format="$#0.00"
                      :min="0"
                      style="margin-right: 3px;" :step="0.1"
                    />
                    <dx-util-number-box
                      v-if="currentOrder.fbmResourceType === 'AMAZON'"
                      v-model="box.insuredValue" type="default"
                      label="Insurance" format="$#0.00"
                      :min="0"
                      style="margin-right: 3px;" :step="0.1"
                    />
                    <dx-util-button v-if="currentOrder.fbmResourceType === 'AMAZON'" icon="icon bi-coin" type="default" hint="Calculate the insurance value for  this box" class="mr-1 mt-half" @click="calculateInsuranceValue(data.no)" />
                    <dx-util-check-box v-if="currentOrder.fbmResourceType === 'AMAZON'" v-model="data.isHazmat" text="Hazmat" class="mr-1" />
                  </div>
                  <div class="divider my-1">
                    <div class="divider-text text-warning">
                      Items
                    </div>
                  </div>
                  <div v-for="item in data.shipBox" :key="item.pshProductId" class="d-flex align-items-center justify-content-between">
                    <div class="flex-grow-1">
                      {{ item.productName }}
                    </div>
                    <dx-util-number-box v-model="item.updatedQuantity" width="60" label="QTY" class="mr-half" />
                    <dx-util-button icon="remove" type="danger" class="mt-half" @click="removeItem(data.no, item)" />
                  </div>
                </div>
              </template>
            </dx-accordion>
          </div>
        </div>
        <div v-if="false">
          <div v-for="box in boxes" :key="box.id">
            <h1>
              {{ box.id }}
            </h1>
            <div>
              <div v-for="item in orderItems" :key="item.id">
                {{ item }}
              </div>
            </div>
            div
          </div>
        </div>
        <div class="text-right mt-1">
          <dx-util-button text="Close" type="success" style="width:100px" @click="closePopup" />
        </div>
      </div>
    </dx-util-scroll-view>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import { v4 as uuidv4 } from 'uuid'
import DxAccordion from 'devextreme-vue/accordion'
import notify from '@/@robustshell/utils/notify'
import useFbmState from '../useFbmStates'
import FbmBoxingCounter from './FbmBoxingCounter.vue'
import FbmBoxingBoxedCount from './FbmBoxingBoxedCount.vue'
import FbmItemWeight from './FbmItemWeight.vue'

export default {
  components: {
    'dx-accordion': DxAccordion,
    'fbm-boxing-counter': FbmBoxingCounter,
    'fbm-boxed-count': FbmBoxingBoxedCount,
    'fbm-item-weight': FbmItemWeight,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
  },
  setup() {
    const {
      boxes,
      currentOrder, getShippingLabels, orderItems, selectedOrderItems,
    } = useFbmState()
    return {
      boxes,
      currentOrder,
      getShippingLabels,
      orderItems,
      selectedOrderItems,
    }
  },
  data() {
    return {
      popupTitle: '',
      showBoxes: false,
      showInputField: false,
      box: {
        id: '',
        count: 1,
        length: 0,
        width: 0,
        height: 0,
        weightLb: 0,
        weightOz: 0,
        description: '',
        insuredValue: 0,
        isHazmat: false,
        singleItemUpdateQty: 0,
        shipBox: [],
      },
    }
  },
  computed: {
    fbmBoxingPopup() {
      return this.$refs.fbmBoxingPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.fbmBoxingPopup.show()
      },
    },
  },
  methods: {
    createBoxes(e) {
      this.showBoxes = true
      this.boxes = []
      const boxValidation = e.validationGroup.validate()
      if (boxValidation.isValid) {
        for (let index = 0; index < this.box.count; index++) {
          const newBox = { ...this.box }
          newBox.id = uuidv4()
          newBox.no = index + 1
          this.boxes.push(newBox)
        }
      }
    },
    fbmBoxingPopupShown() {
      this.popupTitle = 'FBM Boxing'
    },
    fbmBoxingPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.fbmBoxingPopup.hide()
    },
    calculateInsuranceValue(boxNo) {
      const matchingBox = this.boxes.find(el => el.no === boxNo)
      if (matchingBox.shipBox.lengt === 0) {
        notify.error('At least one item should be in the box')
        return
      }
      if (matchingBox) {
        const totalValue = matchingBox.shipBox.reduce((tv, item) => {
          const itemTotalValue = item.price * item.orderQuantity
          return tv + itemTotalValue
        }, 0)
        const formattedTotalValue = totalValue.toFixed(2)
        this.$set(matchingBox, 'insuredValue', parseFloat(formattedTotalValue))
      } else {
        notify.error(`Box with number ${boxNo} not found`)
      }
    },

    removeItem(boxNo, itemToRemove) {
      const matchingBoxIndex = this.boxes.findIndex(box => box.no === boxNo)
      if (matchingBoxIndex !== -1) {
        const updatedShipBox = this.boxes[matchingBoxIndex].shipBox.filter(
          shipItem => shipItem.id !== itemToRemove.id,
        )
        this.$set(this.boxes[matchingBoxIndex], 'shipBox', updatedShipBox)
        this.$set(this.boxes[matchingBoxIndex], 'insuredValue', 0)
      } else {
        notify.error(`Box with number ${boxNo} not found`)
      }
    },
    removeBox(boxNo) {
      this.boxes = this.boxes.filter(el => el.no !== boxNo)
    },
  },
}
</script>

<style lang="scss" scoped>
.custom-border {
  border: 0.5px solid rgb(68, 77, 85);
  margin: 6px;
  padding: 6px;
  border-radius: 6px;
}

</style>
